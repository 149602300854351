"use client";
import dynamic from "next/dynamic";
import { Provider } from "react-redux";
import {store, persistor} from "./store";
import Header from "../_components/Header/Header";
import { useOnInteraction } from "../_components/useOnInteraction/useOnInteraction";
import { PersistGate } from "redux-persist/integration/react";
const Footer = dynamic(() => import("../_components/Footer/Footer"));
export const ReduxProvider = ({ children }) => {
  const isUserInteracted = useOnInteraction()
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Header />
        {children}
        {isUserInteracted && <Footer />} 
      </PersistGate>
    </Provider>
  );
};
