"use client";

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency } from "@/app/[locale]/redux/currencySlice/currencySlice";
import { setAreaUnit } from "@/app/[locale]/redux/areaUnitSlice/areaUnitSlice";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useTranslations, useLocale } from 'next-intl';

// Dynamic import for Dropdown Arrow SVG
const DropDownArrow = dynamic(
  () => import("../../../../../assets/Icons/dropdownArrow.svg"),
  {
    ssr: false, // Ensure it's only rendered on the client-side
  }
);

const LangCurrSwitcher = ({ isOpen, setIsOpen, currencies, areaUnits }) => {

  const t = useTranslations('lang_switcher');
  const locale = useLocale()
  const isRTL = locale === 'ar'
  const direction = isRTL ? 'rtl' : "ltr"

  const dispatch = useDispatch();

  // Access current selections from Redux store
  const currency = useSelector((state) => state.currency.value);
  const areaUnit = useSelector((state) => state.areaUnit.value);

  // Ref for detecting clicks outside the dropdown
  const dropdownRef = useRef(null);

  // Effect to handle clicks outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  // Handler for Currency Change
  const handleCurrencyChange = (newCurrency) => {
    dispatch(setCurrency(newCurrency));
    setIsOpen(false); // Close dropdown after selection
  };

  // Handler for Area Unit Change (if applicable)
  const handleAreaUnitChange = (newAreaUnit) => {
    dispatch(setAreaUnit(newAreaUnit));
    setIsOpen(false); // Close dropdown after selection
  };

  // Toggle Dropdown Visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Preload flag images
  useEffect(() => {
    if (currencies && currencies.length > 0) {
      currencies.forEach((currencyItem) => {
        const img = new window.Image();
        img.src = `/${currencyItem.value}.svg`;
      });
    }
  }, [currencies]);

  return (
    <>
      <div className={`langCurrSwitcher ${isRTL ? 'ar' : ''}`} dir={direction}>
        <div className="container" ref={dropdownRef}>
          <button onClick={toggleDropdown} className={`dropdown-button ${!isOpen ? 'show' : 'hide'} ${isRTL ? 'ar' : ''}`}>
            <span className="text">
              <Image
                src={`/${currency}.svg`}
                alt={`${currency} flag`}
                width={20}
                height={15}
                priority
                className={`flag ${isRTL ? 'ar' : ''}`}
              />
              {t(currency.toLowerCase())} / {t(areaUnit.toLowerCase())}
            </span>
            <span className={`icon ${isRTL ? 'ar' : ''}`}>
              <DropDownArrow />
            </span>
            <Image
              className={`flagMobile ${isRTL ? 'ar' : ''}`}
              src={`/${currency}.svg`}
              alt={`${currency} flag`}
              width={20}
              height={15}
              priority
            />
          </button>
          <div className={`dropDown ${isOpen ? 'show' : 'hide'}`}>
            <ul className={`listDropdownitems ${isRTL ? 'ar' : ''}`}>
              {/* Currency Options */}
              {currencies && currencies.length > 0 && (
                <div>
                  {currencies.map((currencyItem) => (
                    <li
                      key={currencyItem.value}
                      onClick={() =>
                        handleCurrencyChange(currencyItem.value)
                      }
                      className="dropdown-item"
                    >
                      <span className="text">
                        <Image
                          src={`/${currencyItem.value}.svg`}
                          alt={`${currencyItem.label} flag`}
                          width={20}
                          height={15}
                          priority
                        />{" "}
                        {t(currencyItem.label)}
                      </span>
                    </li>
                  ))}
                </div>
              )}
            </ul>
            <div className="separator">&nbsp;</div>
            <ul className="listDropdownitems">
              {/* Area Unit Options */}
              {areaUnits && areaUnits.length > 0 && (
                <div>
                  {areaUnits.map((unit) => (
                    <li
                      key={unit.value}
                      onClick={() => handleAreaUnitChange(unit.value)}
                      className={`dropdown-item ${isRTL ? 'ar': ''}`}
                    >
                      <span
                        className="text"
                        style={{ cursor: "pointer" }}
                      >
                        {t(unit.label)}
                      </span>
                    </li>
                  ))}
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LangCurrSwitcher;