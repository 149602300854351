"use client";

import React, { useState, useEffect, useRef } from "react";
import { useLocale } from 'next-intl';
import { usePathname, useRouter } from '@/i18n/routing';
import dynamic from "next/dynamic";
import Image from "next/image";

const DropDownArrow = dynamic(() =>
  import('../../../../../../assets/Icons/dropdownArrow.svg')
);

const LanguageSwitcherMobile = () => {
  const router = useRouter();
  const pathname = usePathname();
  const currentLocale = useLocale();
  const isRTL = currentLocale === 'ar'

  const [isOpen, setIsOpen] = useState(false);
  const switcherRef = useRef(null);

  const languages = [
    { label: "English", value: "en", flag: { name: 'gb', url: '/flags/gb.svg', alternativeText: "Great Britain Flag" } },
    { label: "العربية", value: "ar", flag: { name: 'uae', url: '/flags/uae.svg', alternativeText: "United Arab Emirates Flag" }},
  ];

  const currentLanguage = languages.find(lang => lang.value === currentLocale);

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const switchLanguage = (locale) => {
    if (locale === currentLocale) return;

    router.replace(pathname, { locale: locale });
    setIsOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (switcherRef.current && !switcherRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="language-switcher-mobile"
      ref={switcherRef}
      onMouseEnter={openDropdown}
      onMouseLeave={closeDropdown}
      onFocus={openDropdown}
      onBlur={closeDropdown}
    >
      <button
        className="switcher-button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Image src={currentLanguage.flag.url} fetchpriority="high" decoding="async" data-nimg="1" style={{color: 'transparent'}}  alt={currentLanguage.flag.alternativeText} width={20} height={15} className={`flag ${isRTL ? 'ar' : ''}`} />
        <span className="text">{currentLanguage.label}</span>
        <span className={`icon ${isRTL ? 'ar' : ''}`}>
          <DropDownArrow />
        </span>
      </button>
      {isOpen && (
        <ul className="switcher-dropdown gradientBorder">
          {languages.map((lang) => (
            <li 
              key={lang.value} 
              className={`switcher-option ${isRTL ? 'ar' : ''}`}
              onClick={() => switchLanguage(lang.value)}
              aria-current={lang.value === currentLocale ? "true" : "false"}
              >
                {lang.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcherMobile;