"use client";
import React, { useEffect, useRef, useState } from "react";
import { Link } from '@/i18n/routing';
import { usePathname } from "@/i18n/routing";
import SubListLinks from "./SubListLinks";
import { subLinkPathnameActiveFinder } from "@/app/[locale]/_utils/utils";
import dynamic from "next/dynamic";
import { useTranslations, useLocale } from 'next-intl';

// Dynamically import the dropdown arrow icon
const DropDownArrow = dynamic(() =>
  import("../../../../../assets/Icons/dropdownArrow.svg")
);

const ListLink = ({
  subLink,
  label,
  path,
  setResponsiveMenuIsActive,
  responsive,
  fullValue 
}) => {
  const pathname = usePathname();

  const locale = useLocale()
  const isRTL = locale === 'ar'

  const [isSubLinkDropDown, setIsSubLinkDropDown] = useState(false);
  const subLinkRef = useRef(null);

  useEffect(() => {
    // Automatically open the dropdown if the path matches
    if (path === fullValue?.path && subLink?.length) {
      setIsSubLinkDropDown(true);
    }
  }, [path, fullValue?.path, subLink?.length]);

  const responsiveMenuClickHandler = (e, sublinkClick) => {
    if (responsive) {
      e.stopPropagation();

      // Toggle the dropdown if the same menu is clicked
      if (subLink?.length && path === fullValue?.path) {
        setIsSubLinkDropDown((prevState) => !prevState);
      }

      if (!subLink?.length) {
        setResponsiveMenuIsActive(false);
        setIsSubLinkDropDown(false);
      }

      if (sublinkClick) {
        setResponsiveMenuIsActive(false);
        setIsSubLinkDropDown(false);
      }
    }
  };

  return (
    <li
      className={
        pathname === path
          ? `active link ${isSubLinkDropDown && "dropDownExist"}`
          : subLinkPathnameActiveFinder(subLink, pathname) === true
          ? `active link ${isSubLinkDropDown && "dropDownExist"}`
          : `link ${isSubLinkDropDown && "dropDownExist"}`
      }
      onClick={(e) => responsiveMenuClickHandler(e)}
    >
      {responsive && subLink?.length ? (
        <div className="linkContainer">
          <span className="label">{label}</span>
          <span className="icon">
            <DropDownArrow />
          </span>
        </div>
      ) : (
        <>
          {!path && <span className="mainLink">{label}</span>}
          {path && (
            <Link className="mainLink" href={path} prefetch={true}>
              {label}
            </Link>
          )}
          {subLink?.length && (
            <span className={`icon ${isRTL ? 'ar' : ''}`}>
              <DropDownArrow />
            </span>
          )}
        </>
      )}
      {isSubLinkDropDown && (
        <ul className="subLinks gradientBorder" onClick={(e) => responsiveMenuClickHandler(e, true)}>
          {subLink?.map((item) => (
            <SubListLinks
              key={item?.id}
              subLinkPath={item?.path}
              subLinkLabel={item?.label}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default ListLink;